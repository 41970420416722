var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoadingCard
    }
  }, [_c('div', {
    staticClass: "card mt-2 pb-3"
  }, [_c('h2', {
    staticClass: "p-2 mt-3 text-primary ml-4"
  }, [_vm._v("Create New Discount Code")]), _c('hr'), _c('div', {
    staticClass: "row mx-3"
  }, [_c('div', {
    staticClass: "col-sm-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "activeDiscount"
    }
  }, [_vm._v("Active Discount"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "type to find active discount",
      "open-direction": "bottom",
      "options": _vm.items_search,
      "searchable": true,
      "loading": _vm.isLoadingSearch,
      "close-on-select": true
    },
    on: {
      "search-change": _vm.asyncFind,
      "select": _vm.actionFindOne
    },
    model: {
      value: _vm.activeDiscount,
      callback: function ($$v) {
        _vm.activeDiscount = $$v;
      },
      expression: "activeDiscount"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v(" Oops! No elements found. Consider changing the search query. ")])])], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "totalCoupon"
    }
  }, [_vm._v("Total Coupon"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.totalCoupon,
      expression: "totalCoupon"
    }],
    staticClass: "form-control rounded",
    attrs: {
      "type": "text",
      "id": "totalCoupon",
      "placeholder": "type total coupon.."
    },
    domProps: {
      "value": _vm.totalCoupon
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.totalCoupon = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "discountPrice"
    }
  }, [_vm._v("Discount Price"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discountPrice,
      expression: "discountPrice"
    }],
    staticClass: "form-control rounded",
    attrs: {
      "type": "text",
      "id": "discountPrice",
      "placeholder": "type discount price.."
    },
    domProps: {
      "value": _vm.discountPrice
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.discountPrice = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newUser,
      expression: "newUser"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "newUser"
    },
    domProps: {
      "checked": Array.isArray(_vm.newUser) ? _vm._i(_vm.newUser, null) > -1 : _vm.newUser
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.newUser,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.newUser = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.newUser = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.newUser = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "form-check-label",
    attrs: {
      "for": "newUser"
    }
  }, [_vm._v("Assign for New User Only")]), _c('small', {
    staticClass: "form-text text-muted"
  }, [_vm._v("** Check to make coupons only valid for new users registration during the promo period.")])])]), _c('div', {
    staticClass: "col-sm-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "minPrice"
    }
  }, [_vm._v("Min. Price : " + _vm._s(_vm.minimalPrice) + " ")])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "maxPrice"
    }
  }, [_vm._v("Max. Price : " + _vm._s(_vm.maximalPrice))])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "combinePromotion"
    }
  }, [_vm._v("Combine Promotion")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.combinePromotion,
      expression: "combinePromotion"
    }],
    staticClass: "form-control rounded",
    attrs: {
      "id": "combinePromotion"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.combinePromotion = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("Coupon can combine with other promos")]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v("Coupon CANT combine with other promos")])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "couponPrefix"
    }
  }, [_vm._v("Coupon Prefix"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.couponPrefix,
      expression: "couponPrefix"
    }],
    staticClass: "form-control rounded",
    attrs: {
      "type": "text",
      "id": "couponPrefix",
      "placeholder": "type coupon prefix.."
    },
    domProps: {
      "value": _vm.couponPrefix
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.couponPrefix = $event.target.value;
      }
    }
  }), _c('small', {
    staticClass: "form-text text-muted"
  }, [_vm._v("** Space character will be removed")])]), _c('div', {
    staticClass: "form-group form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.prefixChar,
      expression: "prefixChar"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "prefixChar"
    },
    domProps: {
      "checked": Array.isArray(_vm.prefixChar) ? _vm._i(_vm.prefixChar, null) > -1 : _vm.prefixChar
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.prefixChar,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.prefixChar = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.prefixChar = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.prefixChar = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "form-check-label",
    attrs: {
      "for": "prefixChar"
    }
  }, [_vm._v("Additional Random Character")]), _c('small', {
    staticClass: "form-text text-muted"
  }, [_vm._v("** If this form is checked, Coupon Prefix will be added with random characters.")])])]), _c('div', {
    staticClass: "col-sm-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "discountUSD"
    }
  }, [_vm._v("Discount USD : " + _vm._s(_vm.discountUsd) + " ")])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "discountIDR"
    }
  }, [_vm._v("Discount IDR : " + _vm._s(_vm.discountIdr))])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "maximumUsage"
    }
  }, [_vm._v("Maximum Usage"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.maximumUsage,
      expression: "maximumUsage"
    }],
    staticClass: "form-control rounded",
    attrs: {
      "type": "text",
      "id": "maximumUsage",
      "placeholder": "type maximum usage.."
    },
    domProps: {
      "value": _vm.maximumUsage
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.maximumUsage = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "specifiedUsers"
    }
  }, [_vm._v("Assign for Specified Users")]), _c('multiselect', {
    attrs: {
      "label": "email",
      "track-by": "email",
      "placeholder": "type to find user",
      "open-direction": "bottom",
      "options": _vm.items_search_user,
      "searchable": true,
      "loading": _vm.isLoadingSearchUser,
      "close-on-select": true,
      "multiple": true
    },
    on: {
      "search-change": _vm.asyncFindUser
    },
    model: {
      value: _vm.specifiedUsers,
      callback: function ($$v) {
        _vm.specifiedUsers = $$v;
      },
      expression: "specifiedUsers"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v(" Oops! No elements found. Consider changing the search query. ")])]), _c('small', {
    staticClass: "form-text text-muted"
  }, [_vm._v("** Specific coupons only apply to certain users")])], 1)]), _c('div', {
    staticClass: "col-sm-12"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": function ($event) {
        return _vm.createDiscountCode();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus mr-2"
  }), _c('span', [_vm._v("Create New Discount Code")])])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }