<template>
  <div>
    <b-overlay :show="isLoadingCard">
      <div class="card mt-2 pb-3">
        <h2 class="p-2 mt-3 text-primary ml-4">Create New Discount Code</h2>
        <hr />
        <div class="row mx-3">
          <div class="col-sm-4">
            <div class="form-group">
              <label for="activeDiscount"
                >Active Discount<span class="text-danger">*</span></label
              >

              <multiselect
                v-model="activeDiscount"
                label="name"
                track-by="name"
                placeholder="type to find active discount"
                open-direction="bottom"
                :options="items_search"
                :searchable="true"
                :loading="isLoadingSearch"
                :close-on-select="true"
                @search-change="asyncFind"
                @select="actionFindOne"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </div>
            <div class="form-group">
              <label for="totalCoupon"
                >Total Coupon<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control rounded"
                id="totalCoupon"
                v-model="totalCoupon"
                placeholder="type total coupon.."
              />
            </div>
            <div class="form-group">
              <label for="discountPrice"
                >Discount Price<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control rounded"
                id="discountPrice"
                v-model="discountPrice"
                placeholder="type discount price.."
              />
            </div>
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="newUser"
                v-model="newUser"
              />
              <label class="form-check-label" for="newUser"
                >Assign for New User Only</label
              >
              <small class="form-text text-muted"
                >** Check to make coupons only valid for new users registration
                during the promo period.</small
              >
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="minPrice">Min. Price : {{ minimalPrice }} </label>
            </div>
            <div class="form-group">
              <label for="maxPrice">Max. Price : {{ maximalPrice }}</label>
            </div>
            <div class="form-group">
              <label for="combinePromotion">Combine Promotion</label>
              <select
                class="form-control rounded"
                id="combinePromotion"
                v-model="combinePromotion"
              >
                <option value="1">Coupon can combine with other promos</option>
                <option value="2">Coupon CANT combine with other promos</option>
              </select>
            </div>
            <div class="form-group">
              <label for="couponPrefix"
                >Coupon Prefix<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control rounded"
                id="couponPrefix"
                v-model="couponPrefix"
                placeholder="type coupon prefix.."
              />
              <small class="form-text text-muted"
                >** Space character will be removed</small
              >
            </div>
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="prefixChar"
                v-model="prefixChar"
              />
              <label class="form-check-label" for="prefixChar"
                >Additional Random Character</label
              >
              <small class="form-text text-muted"
                >** If this form is checked, Coupon Prefix will be added with
                random characters.</small
              >
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="discountUSD">Discount USD : {{ discountUsd }} </label>
            </div>
            <div class="form-group">
              <label for="discountIDR">Discount IDR : {{ discountIdr }}</label>
            </div>
            <div class="form-group">
              <label for="maximumUsage"
                >Maximum Usage<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control rounded"
                id="maximumUsage"
                v-model="maximumUsage"
                placeholder="type maximum usage.."
              />
            </div>
            <div class="form-group">
              <label for="specifiedUsers">Assign for Specified Users</label>
              <multiselect
                v-model="specifiedUsers"
                label="email"
                track-by="email"
                placeholder="type to find user"
                open-direction="bottom"
                :options="items_search_user"
                :searchable="true"
                :loading="isLoadingSearchUser"
                :close-on-select="true"
                :multiple="true"
                @search-change="asyncFindUser"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
              <small class="form-text text-muted"
                >** Specific coupons only apply to certain users</small
              >
            </div>
          </div>
          <div class="col-sm-12">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              @click="createDiscountCode()"
            >
              <i class="fa fa-plus mr-2"></i>
              <span>Create New Discount Code</span>
            </button>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import API from '../../plugins/http';
import Utils from '../../plugins/util';
import constant from '../../store/constant';
import { mapActions } from 'vuex';
export default {
  name: 'DiscountCode',
  data() {
    return {
      isLoadingCard: false,
      prefixChar: false,
      debounce: null,
      activeDiscount: [],
      totalCoupon: '',
      discountPrice: '',
      newUser: false,
      combinePromotion: 1,
      couponPrefix: '',
      maximumUsage: '',
      specifiedUsers: [],
      filter: {},
      isLoadingSearch: false,
      isLoadingSearchUser: false,
      items_search: [],
      items_search_user: [],
      minimalPrice: '-',
      maximalPrice: '-',
      discountUsd: '-',
      discountIdr: '-',
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Create Discount Code | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  methods: {
    ...mapActions('discounts', ['getDiscountName', 'fetchDiscountById']),
    ...mapActions('users', ['fetchUsers']),
    asyncFind(keyword) {
      if (keyword.length > 2) {
        this.isLoadingSearch = true;
        let payload = {
          search: Utils.encodeUrlWithBase64(keyword),
          discount_type: constant.DISCOUNT_TYPES['Discount Code']
        };
        this.getDiscountName(payload)
          .then((resp) => {
            this.items_search = resp.data.data.rows;
            this.isLoadingSearch = false;
          })
          .catch((err) => {
            this.isLoadingSearch = false;
            console.log(err);
          });
      }
    },

    actionFindOne(query) {
      this.fetchDiscountById(query).then((res) => {
        this.minimalPrice = res.data.data.min_idr_order_price
          ? res.data.data.min_idr_order_price
          : '0';
        this.maximalPrice = res.data.data.max_idr_order_price
          ? res.data.data.max_idr_order_price
          : '0';
        this.discountUsd = res.data.data.discount_usd
          ? `$ ${res.data.data.discount_usd}`
          : '0';
        this.discountIdr = res.data.data.discount_idr
          ? `Rp ${res.data.data.discount_idr}`
          : '0';
      });
    },

    asyncFindUser(email) {
      if (email.length > 2) {
        this.isLoadingSearchUser = true;

        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          let payload = {
            email,
          };
          this.fetchUsers(payload)
            .then((resp) => {
              this.items_search_user = resp.data.data.rows;
              this.isLoadingSearchUser = false;
            })
            .catch((err) => {
              this.isLoadingSearchUser = false;
              console.log(err);
            });
        }, 600);
      }
    },
    async createDiscountCode() {
      this.isLoadingCard = true;
      try {
        let url = 'discounts-code/create';
        let payload = {
          activeDiscount: this.activeDiscount.id,
          totalCoupons: this.totalCoupon,
          discountPrice: this.discountPrice,
          newUser: this.newUser,
          combinePromotion: this.combinePromotion,
          couponPrefix: this.couponPrefix.replace(/\s/g, ''),
          maximumUsage: this.maximumUsage,
          specifiedUsers: this.specifiedUsers,
          checkPrefix: this.prefixChar,
        };
        await API.post(url, payload)
          .then((res) => {
            if (res.status === 201) {
              this.$swal({
                icon: 'success',
                toast: true,
                position: 'top-end',
                title: 'Discount Code Has Been Successfully Created',
                timer: 5000,
                text: this.message,
              });

              this.$router.push('/discount-code');
            }
          })
          .catch((error) => {
            this.isLoadingCard = false;
            this.$swal({
              icon: 'error',
              toast: true,
              position: 'top-end',
              title:
                error.response.status === 422
                  ? error.response.data.message
                  : error.response.data.data.message,
              timer: 10000,
              text: this.message,
            });
          });
      } catch (error) {
        this.$swal({
          icon: 'error',
          toast: true,
          position: 'top-end',
          title: 'Failed To Create New Discount Code',
          timer: 4000,
          text: this.message,
        });
      }
    },
  },
};
</script>
